.navbar.navbar-light {
  .cabinet-button {
    transition: background-color 0.2s, border-color 0.2s;

    @include media-breakpoint-up(lg) {
      padding: calc_rem(4px) calc_rem(18px) calc_rem(4px) calc_rem(4px);
    }

    &,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:hover {
      color: $white;
    }
  }

  .show {
    > .cabinet-button {
      color: $white;
    }
  }
}

.cabinet-button {
  .user-sign {
    font-weight: $font-weight-bold;
    letter-spacing: -0.1em;
    color: $primary;
    height: calc_rem(36px);
    min-width: calc_rem(36px);
    max-width: calc_rem(36px);
    margin-right: calc_rem(8px);
  }

  &:hover {
    .user-sign {
      color: $primary-400;
    }
  }

  .show > &,
  &:active,
  &:focus {
    .user-sign {
      color: $primary-800;
    }
  }

  &::after {
    margin-left: calc_rem(21px);
  }
}

.cabinet-button,
.btn-login {
  display: inline-flex;
  align-items: center;
  padding: calc_rem(4px);

  .b-icon {
    font-size: calc_rem(36px) !important;
  }

  @include media-breakpoint-down(md) {
    &.dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: calc_rem(4px) calc_rem(18px) calc_rem(4px) calc_rem(4px);

    .b-icon {
      margin-right: calc_rem(8px);
    }
  }
}

.scroll-btn {
  background-color: $white;
  color: $primary;
  border-radius: 50%;
  font-size: calc_rem(24px);
  display: flex;
  padding: calc_rem(4px);
  transition: $transition-base;

  &:hover {
    background-color: $primary-400;
    box-shadow: 0 0 0 4px $primary-400;
    color: $white;
  }

  &:focus {
    background-color: $primary-800;
    box-shadow: 0 0 0 4px $primary-800;
    color: $white;
  }
}

.btn-scroll-content {
  display: inline-flex;
  align-items: center;
  padding: calc_rem(4px);
  padding-right: calc_rem(12px);
  transition: $transition-base;
  font-weight: $font-weight-bold;
  text-decoration: none;

  .scroll-btn {
    box-shadow: inset 0 0 0 1px $primary, 0 0 0 1px transparent;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $body-color;

    .scroll-btn {
      color: $white;
    }
  }

  &:hover {
    .scroll-btn {
      background-color: $primary-400;
      box-shadow: inset 0 0 0 1px transparent, 0 0 0 4px $primary-400;
    }
  }

  &:focus {
    .scroll-btn {
      background-color: $primary-800;
      box-shadow: inset 0 0 0 1px transparent, 0 0 0 4px $primary-800;
    }
  }
}

.btn-navbar-cta {
  padding: calc_rem(24px);
  font-weight: $font-weight-bold;
  text-decoration: none;
  white-space: nowrap;
  background-color: $primary-100;
  transition: $transition-base;

  &:hover,
  &:focus {
    background-color: $primary-200;
    color: inherit;
    text-decoration: none;
  }
}

.btn-watch-video {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc_rem(16px) calc_rem(16px) calc_rem(32px);
  background-color: $primary;
  border: none;
  width: 160px;
  height: 160px;
  border-radius: 50rem;
  text-align: center;
  box-shadow: 0 0 0 0 $primary;
  font-weight: $font-weight-bold;
  transition: $transition-base;
  position: absolute;
  right: calc_rem(8px);
  bottom: 0;
  white-space: pre;

  &,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
  }

  &:hover {
    background-color: $primary-400;
    box-shadow: 0 0 0 4px $primary-400;
  }

  &:focus {
    background-color: $primary-800;
    box-shadow: 0 0 0 4px $primary-800;
  }

  @include media-breakpoint-up(md) {
    font-size: $font-size-lg;
    width: 200px;
    height: 200px;
  }
}

.btn-start-query {
  padding-left: calc_rem(8px);
  padding-right: calc_rem(32px);

  &::after {
    position: absolute;
    right: calc_rem(16px);
  }
}

a.disabled {
  pointer-events: none;
  opacity: $btn-disabled-opacity;
}
