.apexcharts-wrapper {
  // In px because chart right padding is also in px
  margin-right: -16px;
  margin-left: -16px;

  > * {
    margin-bottom: calc_rem(20px);

    &:last-child {
      margin-bottom: calc_rem(-20px);
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: calc_rem(20px);
      }
    }
  }
}

.apexcharts-canvas {
  .apexcharts-tooltip {
    flex-direction: column-reverse;

    &.apexcharts-theme-light {
      border: none;
      border-radius: $border-radius;
      box-shadow: inset 0 0 0 2px $primary-400;

      .apexcharts-tooltip-title {
        background-color: transparent;
        border: none;
        margin-bottom: 0;
        padding: 0 calc_rem(16px) calc_rem(12px);
      }
    }
  }

  .apexcharts-tooltip-y-group {
    padding: 0;
  }

  .apexcharts-xaxis-label {
    font-weight: $font-weight-normal;
  }

  .apexcharts-tooltip-series-group {
    justify-content: center;
    padding: calc_rem(12px) calc_rem(16px) 0 calc_rem(12px);

    &:last-child {
      padding-bottom: 0;
    }
  }

  .apexcharts-tooltip-text-value {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }

  .apexcharts-toolbar {
    padding: 0;
    // In px because chart right padding is also in px
    margin-right: 16px;
    max-width: none;
    justify-content: flex-end;

    > div {
      width: auto;
      height: auto;
      transform: none;
      line-height: 1;
      color: $primary;
      padding: calc_rem(8px);
      margin-right: 0;
      margin-left: calc_rem(8px);
      border-radius: 50rem;
      transition: $transition-base;

      :first-child {
        margin-left: 0;
      }

      &:hover {
        color: $primary;
        background-color: $primary-200;

        svg {
          fill: currentColor;
        }
      }

      &.apexcharts-selected {
        color: $white;
        background-color: $primary;

        svg {
          fill: currentColor;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      margin-left: auto;
      margin-right: 0;
      margin-top: -40px;
      top: 40px !important;

      > div {
        margin-left: calc_rem(4px);
      }
    }

    svg {
      fill: currentColor;
      stroke: none;
      width: calc_rem(24px);
      height: calc_rem(24px);
    }

    .apexcharts-zoom-icon {
      &,
      &:not(.apexcharts-selected):hover {
        svg {
          fill: none;
        }
      }
    }

    .apexcharts-pan-icon {
      &,
      &:not(.apexcharts-selected):hover {
        svg {
          stroke: none;
        }
      }
    }

    .apexcharts-selected {
      &.apexcharts-zoom-icon {
        svg {
          fill: none;
        }
      }
    }

    .apexcharts-temperature-toggle {
      order: -1;
    }

    .apexcharts-element-hidden {
      display: none;
    }
  }
}
