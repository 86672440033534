.app-datepicker-calendar {
  &.vdp-datepicker__calendar {
    border: 1px solid $dropdown-border-color;
    border-radius: $dropdown-border-radius;
    box-shadow: $box-shadow-lg;
    width: 298px;
    overflow: hidden;
    padding: 0 8px 8px;

    @include media-breakpoint-down(xs) {
      left: -40px;
      right: -40px;
      margin: auto;
    }

    @include media-breakpoint-up(sm) {
      width: 368px;
    }

    header {
      display: flex;
      border-bottom: 1px solid $dropdown-border-color;
      margin: 0 -4px 4px;
      padding: 0 8px;

      span {
        font-weight: $headings-font-weight;
        line-height: 2.8;
        font-size: $h4-font-size;
        width: 70%;
        flex: 1 0 70%;

        &:not(.disabled) {
          &:hover {
            background-color: $gray-100;
          }
        }
      }

      .prev,
      .next {
        width: 15%;
        flex: 0 0 15%;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          border: none;
          top: auto;
          left: auto;
          transform: none;
          width: 24px;
          height: 24px;
          background-image: url('../images/icons/chevron-big-right.svg');
          background-size: cover;
        }

        &.disabled {
          opacity: 0;
          pointer-events: none;
        }
      }

      .prev {
        &::after {
          transform-origin: center;
          transform: rotate(180deg);
        }
      }
    }

    .cell {
      font-weight: $font-weight-bold;
      width: 40px;
      height: 40px;
      line-height: 40px;

      &:not(.blank) {
        &:not(.disabled) {
          &,
          &:hover {
            &.day,
            &.month,
            &.year {
              border: none;
            }
          }
        }
      }

      @include media-breakpoint-up(sm) {
        width: 50px;
        height: 48px;
        line-height: 48px;
      }

      &.month,
      &.year {
        width: calc(100% / 3);
      }

      &.day-header {
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
        line-height: 45px;
        height: 45px;
      }

      &.sun {
        background-color: $gray-100;
      }

      &:not(.blank) {
        &:not(.disabled) {
          &.day,
          &.month,
          &.year {
            &:hover {
              color: $white;
              background-color: $primary-800;
            }
          }
        }
      }

      &.selected {
        color: $white;

        &,
        &:hover {
          background-color: $primary;
        }
      }

      &.highlighted {
        color: $white;

        &,
        &.selected,
        &:hover {
          background-color: $primary;
        }
      }
    }
  }
}

.schedule-datepicker-input {
  &::placeholder {
    opacity: 1;
    color: $body-color;
  }

  + .input-group-append {
    cursor: pointer;
  }
}
