.content-page {
  > img,
  > .embed-responsive,
  .figure {
    display: block;
    margin-top: calc_rem(24px);
    margin-bottom: calc_rem(40px);
  }

  .figure {
    img {
      margin-bottom: 0;
    }

    .figure-caption {
      margin-top: calc_rem(16px);
      text-align: center;
    }
  }
}

.logo-section {
  img {
    transition: $transition-base;
  }

  a {
    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }
}
