// App icons
.app-icon {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;

  .btn & {
    font-size: 133.4%;
    margin-bottom: calc_rem(-1px);
    vertical-align: text-bottom;
  }
}

.app-icon-sm {
  font-size: calc_rem(16px);
  flex: 0 0 calc_rem(16px);
  margin-top: -0.05em;
}

.app-icon-lg {
  font-size: calc_rem(24px);
  flex: 0 0 calc_rem(24px);
  margin-top: -0.042em;

  .btn & {
    &.b-icon {
      margin-top: -0.06em;
      vertical-align: -0.15em;
    }
  }
}

.app-icon-loading {
  animation: rotate 3s linear infinite;

  circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 2.5s ease-in-out infinite;
    stroke-linecap: round;
  }
}

.app-icon-collapse {
  display: flex;
  border: 1px solid $primary;
  border-radius: 50rem;
  padding: calc_rem(3px);
  width: calc_rem(32px);
  height: calc_rem(32px);
}
