.youtube-video-modal {
  .youtube-modal-close {
    position: fixed;
    top: calc_rem(16px);
    right: calc_rem(16px);
  }

  .modal-dialog {
    max-width: 100%;
    margin: 0;
    padding: calc_rem(64px) calc_rem(16px);

    @include media-breakpoint-up(md) {
      padding: calc_rem(16px) calc_rem(64px);
    }
  }

  .modal-dialog-centered {
    min-height: 100%;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    background-color: transparent;
  }

  .embed-responsive,
  .embed-responsive-item {
    max-height: calc(100vh - #{calc_rem(32px)});
  }
}
