.dropdown-toggle {
  display: flex;
  align-items: center;

  &::after {
    content: '';
    display: block;
    border-style: solid;
    border-color: transparent currentColor currentColor transparent;
    border-width: 4px;
    display: inline-block;
    padding: 0;
    transform: rotate(45deg);
    margin-left: calc_rem(9px);
    margin-top: calc_rem(-5px);
  }

  &.dropdown-toggle-split {
    &::after {
      margin-left: 0;
    }
  }

  .language-dropdown & {
    font-size: $small-font-size;

    &::after {
      color: $primary;
    }
  }
}

.dropdown-toggle {
  .cabinet-button-inner-username {
    margin-right: 5px;
  }
}

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;

  .language-dropdown & {
    font-size: $small-font-size;
    min-width: calc_rem(60px);
    left: calc_rem(32px);
    border-radius: $border-radius-sm;
    box-shadow: $box-shadow-lg;
    border-width: 1px;
  }

  .account-dropdown & {
    min-width: calc_rem(240px);
    position: absolute;
    float: left;
  }
}

.dropdown-item {
  &:hover,
  &:active,
  &:focus {
    span {
      color: inherit !important;
    }

    .b-icon {
      color: inherit !important;
    }
  }
}
