.toast-body {
  padding: $toast-padding-y $toast-padding-x;
}

.b-toaster-slot {
  &:not(:empty) {
    display: flex;
  }

  flex-direction: column;
  align-items: center;
}
