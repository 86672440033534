.map-section {
  background-color: $white;
  box-shadow: $box-shadow-xs;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;

  @include media-breakpoint-up(md) {
    border-bottom-left-radius: 0;
    border-top-right-radius: $border-radius-lg;
  }
}
