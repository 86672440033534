.app-notice {
  background-color: $gray-100;
  box-shadow: inset 0 0 0 1px $border-color;
  border-left: 6px solid;
  margin: calc_rem(24px) 0 calc_rem(40px);
  padding: calc_rem(32px) calc_rem(24px) calc_rem(16px);
  border-radius: 0 calc_rem(8px) calc_rem(8px) 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: calc_rem(24px);
  }
}

@each $color, $value in $theme-colors {
  .app-notice-#{$color} {
    border-left-color: theme-color($color);
  }
}
