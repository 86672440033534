.compact-schedule-calendar {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .compact-schedule-day {
    flex: 1 1 auto;
    display: flex;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
      max-width: calc(100% / 7);
      flex: 0 0 calc(100% / 7);
    }

    &.is-today {
      .compact-schedule-day-header {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .compact-schedule-day-header {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $primary-100;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: flex-end;
      border-radius: $border-radius;
    }
  }

  .compact-schedule-date {
    margin-top: calc_rem(-2px);

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .compact-schedule-tasks {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  .compact-schedule-add-task,
  .compact-schedule-task-busy,
  .compact-schedule-task-placeholder {
    flex: 1 1 auto;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .compact-schedule-add-task,
  .compact-schedule-task-busy {
    min-height: calc_rem(40px);
  }

  .compact-schedule-task-placeholder {
    min-height: calc_rem(32px);
  }

  .compact-schedule-task-placeholder,
  .compact-schedule-task-busy {
    font-size: $font-size-sm;
    box-shadow: inset 0 0 0 1px $border-color;
  }

  .compact-schedule-task-placeholder {
    &:not(:only-child) {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  .compact-schedule-add-task {
    border-radius: $border-radius;
    box-shadow: inset 0 0 0 2px $border-color;
    padding: calc_rem(8px);
    color: $gray-500;
    transition: color 0.2s, background-color 0.2, box-shadow 0.2s;

    &:hover {
      color: $primary;
      background-color: $gray-100;
      box-shadow: inset 0 0 0 4px $border-color;
    }
  }

  @include media-breakpoint-up(lg) {
    .compact-schedule-task-busy {
      flex: 0 1 auto;
    }
  }

  .compact-schedule-task {
    border-left: 4px solid;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    text-decoration: none;
    transition: background-color 0.2s;
    font-size: $font-size-sm;

    &.is-past {
      opacity: 0.4;
    }

    &:last-child {
      flex: 1 1 auto;
    }

    &.task-bg-success {
      border-left-color: $success;
      background-color: $success-ultralight;
      color: $success-text;

      &:hover {
        background-color: $success-light;
      }

      &.is-past-actionable {
        border-left-color: rgba($success, 0.4);
        background-color: rgba($success-ultralight, 0.4);

        &:hover {
          background-color: rgba($success-light, 0.4);
        }
      }
    }

    &.task-bg-warning {
      border-left-color: $warning;
      background-color: $warning-ultralight;
      color: $warning-text;

      &:hover {
        background-color: $warning-light;
      }

      &.is-past-actionable {
        border-left-color: rgba($warning, 0.4);
        background-color: rgba($warning-ultralight, 0.4);

        &:hover {
          background-color: rgba($warning-light, 0.4);
        }
      }
    }

    &.task-bg-danger {
      border-left-color: $danger;
      background-color: $danger-ultralight;
      color: $danger-text;

      &:hover {
        background-color: $danger-light;
      }

      &.is-past-actionable {
        border-left-color: rgba($danger, 0.4);
        background-color: rgba($danger-ultralight, 0.4);

        &:hover {
          background-color: rgba($danger-light, 0.4);
        }
      }
    }

    &.task-bg-primary {
      border-left-color: $primary;
      background-color: $primary-100;
      color: $primary-text;

      &:hover {
        background-color: $primary-200;
      }

      &.is-past-actionable {
        border-left-color: rgba($primary, 0.4);
        background-color: rgba($primary-100, 0.4);

        &:hover {
          background-color: rgba($primary-200, 0.4);
        }
      }
    }

    .compact-schedule-task-title {
      font-weight: $font-weight-bold;
      line-height: 1.2;
      margin-bottom: calc_rem(2px);
    }
  }
}

.compact-schedule-controls {
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;

    > * {
      width: auto;
    }

    .multiselect {
      width: 280px;
    }
  }
}
