@keyframes icon-pulse-opacity {
  0% {
    font-weight: bold;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes icon-pulse-blue {
  0% {
    color: $body-color;
  }

  50% {
    color: $primary;
  }

  100% {
    color: $body-color;
  }
}

@keyframes pulse-blue {
  0% {
    box-shadow: 0 0 0 0 $primary;
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 $success;
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

@keyframes online-indicator {
  0%,
  100% {
    background-color: $success;
  }

  50% {
    background-color: rgba($success, 0.2);
  }
}

@keyframes pulse-notification {
  0% {
    box-shadow: 0 0 0 0 $white;
  }

  81% {
    box-shadow: 0 0 0 0 $white;
  }

  89.1% {
    box-shadow: 0 0 0 3px $white;
  }

  97.2% {
    box-shadow: 0 0 0 3px $white;
  }

  100% {
    box-shadow: 0 0 0 0 $white;
  }
}

@keyframes rotating-static {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(180deg * 2) scale(0.5);
  }
  50% {
    transform: rotate(360deg * 2) scale(1);
  }
  75% {
    transform: rotate(180deg * 2) scale(0.5);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes invoke {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

// Icon animations
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
