.col-form-label,
label {
  font-weight: $font-weight-bold;
}

.col-form-label {
  padding-bottom: $label-margin-bottom;
}

select {
  &:disabled {
    opacity: 1;
  }
}
