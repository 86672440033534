.custom-checkbox,
.custom-radio,
.custom-switch {
  margin-bottom: calc_rem(12px);

  label {
    font-weight: $font-weight-normal;
  }

  .custom-control-input:disabled ~ .custom-control-label {
    opacity: 0.4;
  }
}

.custom-checkbox {
  .custom-control-input:active ~ .custom-control-label {
    &::after {
      background-image: escape-svg($custom-checkbox-indicator-icon-checked);
    }
  }

  .custom-control-input:disabled:indeterminate ~ .custom-control-label,
  .custom-control-input:disabled:checked ~ .custom-control-label {
    &::before {
      border-color: transparent;
    }
  }
}

.custom-radio {
  .custom-control-input:focus ~ .custom-control-label,
  .custom-control-input:active ~ .custom-control-label,
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border-width: 6px;
      background-color: $white;
    }
  }

  .custom-control-input:disabled:checked ~ .custom-control-label {
    &::before {
      background-color: $white;
    }
  }
}

.custom-switch {
  .custom-control-label {
    &::before {
      top: (
          $font-size-base * $line-height-base - $custom-control-indicator-size -
            calc_rem(4px)
        ) / 2;
      height: $custom-control-indicator-size + calc_rem(4px);
    }

    &::after {
      top: add(
        (
            $font-size-base * $line-height-base - $custom-control-indicator-size -
              calc_rem(4px)
          ) / 2,
        $custom-control-indicator-border-width * 2
      );
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4 4L8 8M12 12L8 8M8 8L12 4M8 8L4 12' stroke='#{$custom-control-indicator-bg}' stroke-width='1.5' stroke-linecap='round'/></svg>")
      );
      background-size: $custom-switch-indicator-size - calc_rem(4px);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $custom-control-indicator-checked-bg;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg(
        url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M2 8L6 12L14 4' stroke='#{$custom-control-indicator-checked-bg}' stroke-width='1.5' stroke-linecap='round'/></svg>")
      );
      background-size: $custom-switch-indicator-size - calc_rem(4px);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $custom-control-indicator-checked-color;
      transform: translateX(
        $custom-switch-width - $custom-control-indicator-size - calc_rem(4px)
      );
    }
  }

  .custom-control-input:indeterminate ~ .custom-control-label {
    &::before {
      border-color: $custom-checkbox-indicator-indeterminate-border-color;
      background-color: $custom-checkbox-indicator-indeterminate-bg;
    }

    &::after {
      background: escape-svg(
        url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3 8H13' stroke='#{$custom-control-indicator-checked-bg}' stroke-width='1.5' stroke-linecap='round'/></svg>")
      );
      background-size: $custom-switch-indicator-size - calc_rem(4px);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $custom-control-indicator-checked-color;
      transform: translateX(50%);
    }
  }
}

.custom-file-input {
  &:focus ~ .custom-file-label {
    box-shadow: inset 0 0 0 $input-border-width $custom-file-focus-border-color;

    &::after {
      background-color: $custom-file-focus-border-color;
    }
  }
}

.custom-file {
  &.is-uploading {
    .custom-file-input {
      &,
      &:focus {
        ~ .custom-file-label {
          &::after {
            color: transparent;
          }
        }
      }
    }
  }

  + .file-uploading {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    color: $custom-file-button-color;
    z-index: 4;
    padding: $custom-file-padding-y $custom-file-padding-x;
    height: calc_rem(48px);

    // Makes file-uploading element the same size as upload label
    &::before {
      font-weight: $font-weight-bold;
      z-index: 3;
      display: block;
      line-height: $custom-file-line-height;
      color: $custom-file-button-color;
      content: attr(data-label);
      opacity: 0;
    }

    .app-icon-loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 24px;
      height: 24px;
    }
  }
}

.custom-file-label {
  border: none;
  box-shadow: inset 0 0 0 $custom-file-border-width $custom-file-border-color;
  transition: $transition-base;

  &::after {
    border-left: none;
    height: $custom-file-height;
    transition: $transition-base;
    font-weight: $font-weight-bold;
  }
}

.custom-control-single-select {
  box-sizing: border-box;
  color: $body-color;
  min-height: $custom-select-height;
  font-weight: $custom-select-font-weight;

  .multiselect__tags {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: $custom-select-height;
    min-height: $custom-select-height;
    padding: $custom-select-padding-y
      ($custom-select-padding-x + $custom-select-indicator-padding)
      $custom-select-padding-y $custom-select-padding-x;
    font-family: $custom-select-font-family;
    font-size: $custom-select-font-size;
    line-height: $custom-select-line-height;
    color: $custom-select-color;
    vertical-align: middle;
    background: $custom-select-bg $custom-select-background;
    border-radius: $custom-select-border-radius;
    border: $custom-select-border-width solid $custom-select-border-color;

    .multiselect__single {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      line-height: $custom-select-line-height;
      font-size: $custom-select-font-size;
    }

    .multiselect__placeholder {
      padding-top: 0;
    }
  }

  .is-invalid & {
    .multiselect__tags {
      border-color: $danger;
    }
  }

  .multiselect__select {
    z-index: 2;
    height: 100%;

    &::before {
      display: none;
    }
  }

  .multiselect__option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    display: flex;
    align-items: center;
  }

  .multiselect__option--selected {
    background-color: $primary;
    color: $white;
    font-weight: $font-weight-normal;
  }

  &.font-weight-bold {
    .multiselect__option--selected {
      font-weight: $font-weight-bold;
    }
  }

  .multiselect__option--highlight {
    color: $white;
    background-color: $primary-400;
  }

  .multiselect__content-wrapper {
    padding: $dropdown-padding-y $dropdown-padding-x;
    border: $dropdown-border-width solid $custom-select-border-color;
    border-bottom-left-radius: $dropdown-border-radius;
    border-bottom-right-radius: $dropdown-border-radius;
    margin-top: -$dropdown-border-width;
    box-shadow: $dropdown-box-shadow;
  }

  &.multiselect--above {
    .multiselect__content-wrapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: $dropdown-border-radius;
      border-top-right-radius: $dropdown-border-radius;
      border-top: $dropdown-border-width solid $custom-select-border-color;
      margin-top: 0;
      margin-bottom: -$dropdown-border-width;
    }

    &.multiselect--active {
      .multiselect__tags {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &:not(.multiselect--above) {
    &.multiselect--active {
      .multiselect__tags {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.custom-control-multi-select {
  flex-wrap: nowrap;

  .multiselect {
    box-sizing: border-box;
    color: $body-color;
    min-height: $custom-select-height;
    font-size: $font-size-base;

    .multiselect__input {
      font-size: $font-size-base;
      margin-bottom: 0;
      padding-left: 0;
    }

    .multiselect__single {
      vertical-align: bottom;
      margin-bottom: 0;
      padding-left: 0;
      background: none;
      font-size: $font-size-base;
    }

    .multiselect__select {
      z-index: 2;
      height: 100%;

      &::before {
        display: none;
      }
    }

    .multiselect__option {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      line-height: inherit;
      display: flex;
      align-items: center;
    }

    .multiselect__option--selected {
      background-color: $white;
      color: inherit;
      font-weight: $font-weight-normal;
    }

    .multiselect__option--highlight {
      color: $white;
      background-color: $primary-400;
    }

    .multiselect__content-wrapper {
      padding: $dropdown-padding-y $dropdown-padding-x;
      border: $dropdown-border-width solid $custom-select-border-color;
      border-top: none;
      border-bottom-left-radius: $dropdown-border-radius;
      border-bottom-right-radius: $dropdown-border-radius;
      box-shadow: $dropdown-box-shadow;
      z-index: -1;
    }

    .multiselect__tags {
      display: inline-flex;
      align-items: center;
      width: 100%;
      height: $custom-select-height;
      min-height: $custom-select-height;
      padding: $custom-select-padding-y
        ($custom-select-padding-x + $custom-select-indicator-padding)
        $custom-select-padding-y $custom-select-padding-x;
      font-family: $custom-select-font-family;
      font-size: $custom-select-font-size;
      font-weight: $custom-select-font-weight;
      line-height: $custom-select-line-height;
      color: $custom-select-color;
      vertical-align: middle;
      background: $custom-select-bg $custom-select-background;
      border-radius: $custom-select-border-radius;
      border: $custom-select-border-width solid $custom-select-border-color;
      position: relative;
      z-index: 1;
    }

    &.multiselect--above {
      .multiselect__content-wrapper {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: $dropdown-border-radius;
        border-top-right-radius: $dropdown-border-radius;
        border-bottom: none;
        border-top: $dropdown-border-width solid $custom-select-border-color;
      }

      &.multiselect--active {
        .multiselect__tags {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }

    &:not(.multiselect--above) {
      &.multiselect--active {
        .multiselect__tags {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .input-group-prepend {
    .multiselect {
      .multiselect__tags {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .input-group-append {
    .multiselect {
      .multiselect__tags {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .multiselect--disabled {
    background: none;
    opacity: 1;

    .multiselect__tags {
      background-color: $input-disabled-bg;
    }
  }

  &.is-invalid {
    .multiselect__tags {
      border-color: $danger;
    }
  }
}

.custom-control-checkbox-select {
  .custom-checkbox {
    &,
    .custom-control-label {
      pointer-events: none;
    }
  }
}
