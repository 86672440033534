.btn {
  &:focus,
  &.focus {
    box-shadow: none !important;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
  }
}

.btn-sm {
  padding-bottom: calc_rem(8px - $btn-border-width);
}

.btn-lg {
  padding-bottom: calc_rem(13.5px - $btn-border-width);
}

.btn-link {
  color: $primary;

  &:hover {
    color: $link-hover-color;
  }
}

.btn-primary {
  &:hover {
    background-color: $primary-400;
    border-color: $primary-400;
  }

  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $primary-800;
    border-color: $primary-800;
  }
}

.btn-outline-primary {
  &:hover {
    color: $primary;
    border-color: $primary;
    box-shadow: inset 0 0 0 1px $primary;
    background-color: $gray-100;
  }

  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $primary-800;
    border-color: $primary-800;
    background-color: $gray-100;
    box-shadow: inset 0 0 0 1px $primary-800 !important;
  }
}

.btn-light {
  background-color: $white;

  &:hover {
    border-color: $primary-400;
    background-color: $primary-400;
    color: $white;
  }

  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: $primary-800;
    background-color: $primary-800;
    color: $white;
  }
}
