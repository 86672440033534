.app-table {
  margin-bottom: 0;

  .table {
    border-collapse: separate;
    border-spacing: 0;

    thead {
      th {
        border-top: none;
        background-color: $table-head-bg;
        border-bottom: none;
        vertical-align: middle;

        .sr-only {
          // Fixes issues with screen reader text in overflow container
          position: relative;
        }
      }
    }

    th,
    td {
      border-top: none;
      border-bottom: $table-border-width solid $table-border-color;
      width: 300px;

      &.app-table-row-collapse {
        width: calc_rem(24px);
      }
    }

    tbody {
      tr {
        &:first-of-type {
          > td {
            border-top: $table-border-width solid $primary;
          }
        }
      }
    }
  }

  .b-table-has-details {
    > td {
      background-color: $primary;
      color: $white;
      border-bottom: none;
    }
  }

  .b-table-details {
    > td {
      border: $table-border-width solid $primary;
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
      padding: calc_rem(24px);
    }
  }
}

.app-table-thead-bordered {
  th {
    + th {
      border-left: $table-border-width solid $table-border-color;
    }
  }
}

.app-list-table {
  thead {
    display: none;
  }

  td {
    vertical-align: middle;

    &:first-child {
      width: 100%;
    }

    &.checkbox-column {
      min-width: 168px;
    }
  }

  .form-control {
    width: 110px;
  }

  @include media-breakpoint-down(sm) {
    .list-row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    td {
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        flex: 1 0 100%;
      }

      &:nth-child(2) {
        flex: 1 0 auto;
        padding-right: calc_rem(8px);
      }

      &:last-child {
        flex: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    thead {
      display: table-header-group;

      th {
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
      }
    }

    .form-control {
      width: 120px;
    }
  }
}

[class*='table-responsive'] {
  &.scrollable {
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: 16px;
      opacity: 1;
      pointer-events: none;
      transition: $transition-base;
    }

    &::before {
      opacity: 0;
      left: 0;
      background: linear-gradient(90deg, rgba($black, 0.1), rgba($black, 0));
    }

    &::after {
      right: 0;
      background: linear-gradient(90deg, rgba($black, 0), rgba($black, 0.1));
    }
  }

  &.scrolling {
    &::before {
      opacity: 1;
    }
  }

  &.scroll-end {
    &::after {
      opacity: 0;
    }
  }
}
