.bottom-arch {
  // This allows background image to go behind the arch
  > :only-child {
    margin-bottom: calc_rem(-64px);
    padding-bottom: calc_rem(64px);

    @include media-breakpoint-up(sm) {
      margin-bottom: calc_rem(-88px);
      padding-bottom: calc_rem(88px);
    }

    @include media-breakpoint-up(md) {
      margin-bottom: calc_rem(-104px);
      padding-bottom: calc_rem(104px);
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: -7.61347%;
      padding-bottom: 7.61347%;
    }
  }

  &::after {
    content: '';
    background-image: url('../images/Footer/arch.svg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    padding-bottom: calc_rem(64px);
    bottom: -1px;

    @include media-breakpoint-up(sm) {
      padding-bottom: calc_rem(88px);
    }

    @include media-breakpoint-up(md) {
      padding-bottom: calc_rem(104px);
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 7.61347%;
    }
  }
}

.footer {
  flex: 0 0 auto;

  .footer-links {
    li {
      margin-bottom: calc_rem(8px);

      &:last-child {
        margin-bottom: 0;
      }

      a {
        text-decoration: none;
        color: $text-muted;

        &:hover,
        &:focus {
          color: $body-color;
          text-decoration: underline;
        }
      }
    }

    .footer-title {
      margin-bottom: calc_rem(16px);
    }
  }

  .social-icon {
    color: $gray-500;
    border-radius: 50%;
    display: inline-flex;
    transition: $transition-base;

    &:hover {
      color: $primary;
    }

    &:focus {
      background-color: $primary-100;
      color: $primary-800;
    }
  }
}
