.alert-dismissible {
  padding-right: $alert-padding-x + calc_rem(8px);

  // Adjust close link position
  .close {
    padding: $alert-padding-y calc_rem(8px);
  }
}

// Override alert-warning colors
.alert-warning {
  @include alert-variant(
    theme-color-level('warning', $alert-warning-bg-level),
    theme-color-level('warning', $alert-warning-border-level),
    theme-color-level('warning', $alert-warning-color-level)
  );
}
