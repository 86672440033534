.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  &.home {
    background-position: center;
    background-image: url('../images/Home/header-background.jpg');
    position: relative;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > * {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      background-color: rgba($white, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.signin {
    background-image: url('../images/Home/signin-background.jpg');
  }
}
