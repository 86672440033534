.feature-card {
  position: relative;

  > * {
    position: relative;
  }

  img {
    width: 64px;
    flex: 0 0 64px;
  }

  &::before {
    content: '';
    background-color: $gray-100;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-radius: 50%;
  }

  @include media-breakpoint-up(md) {
    min-height: 240px;

    &::before {
      width: 240px;
      height: 240px;
    }
  }

  @include media-breakpoint-up(lg) {
    img {
      width: 80px;
      flex: 0 0 80px;
    }
  }
}
