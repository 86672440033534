.linked-card {
  display: block;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  .card {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba($black, 0.2) 0%,
        rgba($black, 0.8) 100%
      );
      opacity: 0.75;
      transition: $transition-base;
    }
  }

  .card-body {
    transition: $transition-base;
  }

  &:hover {
    .card {
      &::before {
        opacity: 1;
      }

      .card-body {
        transform: translateY(-4px);
      }
    }
  }

  .card-img {
    height: 264px;
  }
}

.offer-card {
  .stretched-link {
    &::after {
      border-radius: $border-radius;
      box-shadow: $box-shadow-sm;
      transition: $transition-base;
    }

    &:hover,
    &:focus {
      &::after {
        box-shadow: $box-shadow;
      }
    }
  }
}

.offer-card-content {
  a {
    position: relative;
    z-index: 1;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.dashboard-card {
  &.card {
    height: 100%;
    border: 2px solid $border-color;
    border-radius: $border-radius;
    box-shadow: none;

    img {
      width: 96px;
      height: 96px;
      flex: 0 0 96px;
    }

    .card-body {
      padding: calc_rem(24px);
    }
  }
}

.collapse-toggle {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $body-color;

    .app-icon-collapse {
      box-shadow: 0 0 0 2px $primary;
      background-color: $primary;
    }

    .app-icon {
      color: $white !important;
    }
  }

  &[aria-expanded='true'] {
    .app-icon-collapse {
      transform: rotate(-90deg);
    }
  }

  .app-icon-collapse {
    box-shadow: 0 0 0 2px transparent;
    transition: transform 0.2s ease;
  }
}
