.tooltip-inner {
  border: 2px solid $tooltip-arrow-color;
}

.tooltip-icon {
  font-size: calc_rem(16px);

  &.text-primary {
    &:hover {
      color: $primary-400 !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .b-tooltip-#{$color} {
    .tooltip-inner {
      border-color: theme-color($color);
    }

    &.bs-tooltip-auto[x-placement^='top'],
    &.bs-tooltip-top {
      .arrow {
        &::before {
          border-top-color: theme-color($color);
        }
      }
    }

    &.bs-tooltip-auto[x-placement^='right'],
    &.bs-tooltip-right {
      .arrow {
        &::before {
          border-right-color: theme-color($color);
        }
      }
    }

    &.bs-tooltip-auto[x-placement^='bottom'],
    &.bs-tooltip-bottom {
      .arrow {
        &::before {
          border-bottom-color: theme-color($color);
        }
      }
    }

    &.bs-tooltip-auto[x-placement^='left'],
    &.bs-tooltip-left {
      .arrow {
        &::before {
          border-left-color: theme-color($color);
        }
      }
    }
  }
}
