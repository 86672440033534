.btn-adm-language-select {
  box-shadow: 0 0 4px 0 grey;
  border: none;
  border-radius: 5px;
  .btn {
    border: none;
    background-color: white;
  }
}

.btn-adm-exit.btn {
  border: none;
  background-color: white;
  box-shadow: 0 0 4px 0 grey;
  border-radius: 5px;
}

.admin-panel {
  font-size: 80% ;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 grey;

  .active-link {
    font-weight: bold;
  }
  
  .nav-tabs .nav-link {
    color: darkslategrey;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
