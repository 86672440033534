.pagination {
  .page-link {
    min-width: calc_rem(48px);
    height: calc_rem(48px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
    margin-left: 0 !important;

    .b-icon {
      font-size: calc_rem(24px);
      color: $primary;
    }
  }

  .disabled {
    .page-link {
      opacity: 0.4;
    }
  }
}
