.fieldset-title {
  display: flex;
  align-items: center;

  &::after {
    margin-left: calc_rem(16px);
    margin-top: calc_rem(6px);
    content: '';
    height: 1px;
    background-color: $border-color;
    flex: 1 0 auto;
  }
}

.checkbox-columns {
  @include media-breakpoint-up(sm) {
    columns: 2;
    -webkit-column-break-inside: avoid;
  }

  @include media-breakpoint-up(lg) {
    columns: 3;
  }
}

.service-type-toggle {
  .btn {
    border-radius: $custom-select-border-radius;
    color: $custom-select-color;
    border: $custom-select-border-width solid $custom-select-border-color;
    transition: $input-transition;
    font-weight: $font-weight-bold;
    background: $custom-select-bg $custom-select-background;
    padding: $custom-select-padding-y $custom-select-padding-x;
    height: $custom-select-height;
    line-height: $custom-select-line-height;

    &:after {
      display: none;
    }
  }

  .is-invalid {
    .btn {
      border-color: $form-feedback-invalid-color;
    }
  }
}

.time-input-group {
  display: flex;

  > .form-group {
    flex: 1 0 0%;
  }

  > span {
    line-height: calc_rem(48px);
  }
}

@include media-breakpoint-down(xs) {
  .app-form-controls {
    .btn {
      width: 100%;
    }
  }
}

.feedback-section {
  background-image: url('../images/Home/feedback.jpg');
  background-position: center;
  background-size: cover;
  min-height: 550px;
  position: relative;

  > * {
    position: relative;
    z-index: 2;
  }

  .form-control {
    background-clip: inherit;

    &:not(:focus) {
      border-color: $white;
    }
  }

  &::before {
    content: '';
    background-color: rgba(#3f1909, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.price-input {
  max-width: 200px;
}
