.badge-event-label {
  position: relative;
  top: -0.05em;
  left: calc_rem(8px);
  color: #fff;
}

.badge-amount {
  position: relative;
  top: -0.05em;
  font-family: $font-family-monospace;
  font-weight: bold;
  font-size: $font-size-sm;
  height: calc_rem(24px);
  width: calc_rem(24px);

  .navbar & {
    vertical-align: text-bottom;
    top: 0;
    margin-bottom: calc_rem(-1px);
    font-size: $font-size-xs;
  }
}

.badge-amount-sm { 
  height: calc_rem(20px);
  width: calc_rem(20px);
}

.badge-chat-events {
  display: flex;
  align-items: center;
  justify-content: center;

  .chat-events-counter {
    position: absolute;
    color: $white;
    font-size: $font-size-xs;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
