// Bootstrap Vue Overrides
$bv-enable-tooltip-variants: false;

// Bootstrap overrides
$gray-100: #f8f8f8;
$gray-200: #ebebeb;
$gray-300: #dedede;
$gray-400: #ccc;
$gray-500: #808080;
$gray-600: #555;
$gray-700: #373737;
$gray-800: #2d2d2d;
$gray-900: #222;

$blue: #004bcc;
$red: #e61e1e;
$yellow: #ffd600;
$green: #069964;
$cyan: #066499;

$colors: (
  'blue': $blue,
  'red': $red,
  'yellow': $yellow,
  'green': $green,
  'cyan': $cyan,
);

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red; //login/ sign-up asterisk color
$dark: $gray-600;
$muted: #ccc; //muted

// Theme color variations
$primary-100: #ebf1fb;
$primary-200: #d9e4f7;
$primary-300: #80a5e6;
$primary-400: #336fd6;
$primary-800: #003ca3;
$primary-text: #02283d;

$danger-dark: #a01515;

$success-ultralight: #e6f5ef;
$success-light: #cdebe0;
$success-dark: #046b46;
$success-text: #023d28;

$warning-ultralight: #fff7cc;
$warning-light: #fff3b2;
$warning-text: #4d4000;

$danger-ultralight: #fce8e8;
$danger-light: #fad2d2;
$danger-text: #5c0c0c;

$theme-colors: (
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'danger-dark': $danger-dark,
  'dark': $dark,
  'gray-100': $gray-100,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
);

$container-max-widths: map-merge(
  $container-max-widths,
  (
    xl: 1152px,
  )
);

$grid-gutter-width: 32px;

$spacer: 1rem;
// Reset default spacers
$spacers: ();

// prettier-ignore
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.5
  ),
  3: (
    $spacer * 0.75
  ),
  4: (
    $spacer * 1
  ),
  5: (
    $spacer * 1.25
  ),
  6: (
    $spacer * 1.5
  ),
  8: (
    $spacer * 2
  ),
  9: (
    $spacer * 2.25
  ),
  10: (
    $spacer * 2.5
  ),
  12: (
    $spacer * 3
  ),
  14: (
    $spacer * 3.5
  ),
  15: (
    $spacer * 3.75
  ),
  16: (
    $spacer * 4
  ),
  20: (
    $spacer * 5
  ),
  26: (
    $spacer * 6.5
  ),
  32: (
    $spacer * 8
  ),
  50: (
    $spacer * 12.5
  ),
);

// Body
$body-color: $gray-900;

// Links
$link-color: $body-color;
$link-decoration: underline;
$link-hover-color: $primary-800;

// Components
$border-color: $gray-300;

$border-radius: 12px;
$border-radius-sm: 4px;
$border-radius-lg: 24px;

$box-shadow-xs: 0 1px 4px 0 rgba($black, 0.05), 0 2px 4px 0 rgba($black, 0.1);
$box-shadow-sm: 0 1px 4px 0 rgba($black, 0.05), 0 4px 8px 0 rgba($black, 0.1);
$box-shadow: 0 1px 4px rgba($black, 0.05), 0 8px 16px rgba($black, 0.1);
$box-shadow-lg: 0 1px 4px rgba($black, 0.05), 0 16px 32px rgba($black, 0.1);
$box-shadow-xl: 0 1px 4px rgba($black, 0.05), 0 32px 64px rgba($black, 0.1);

// Typography
$font-size-base: calc_rem(18px);
$font-size-lg: calc_rem(21px);
$font-size-sm: calc_rem(15px);
$font-size-xs: calc_rem(12px);

$line-height-base: 1.4;
$line-height-lg: $line-height-base;
$line-height-sm: $line-height-base;

$h1-font-size: calc_rem(36px);
$h2-font-size: calc_rem(26px);
$h3-font-size: calc_rem(24px);
$h4-font-size: calc_rem(20px);
$h5-font-size: calc_rem(18px);
$h6-font-size: calc_rem(18px);

$headings-margin-bottom: calc_rem(24px);
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.25;

$display1-size: calc_rem(42px);
$display1-weight: $font-weight-normal;

$display2-size: calc_rem(36px);
$display2-weight: $font-weight-normal;

$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base;
$lead-font-weight: $font-weight-normal;

$small-font-size: 83.333333%;

$text-muted: $gray-600;

$hr-border-color: $border-color;

// Tables
$table-cell-padding-sm: calc_rem(4px);

$table-hover-bg: $gray-100;

$table-border-color: $border-color;

$table-head-bg: $gray-100;

// Buttons
$btn-font-size: $font-size-base;
$btn-font-weight: $font-weight-bold;
$btn-line-height: 1.225;

$btn-border-width: 2px;
$btn-padding-y: calc_rem(13px - $btn-border-width);
$btn-padding-x: calc_rem(24px - $btn-border-width);
$btn-border-radius: $border-radius;

$btn-padding-y-sm: calc_rem(6px - $btn-border-width);
$btn-padding-x-sm: calc_rem(14px - $btn-border-width);
$btn-font-size-sm: $font-size-sm;
$btn-line-height-sm: $btn-line-height;
$btn-border-radius-lg: $border-radius;

$btn-padding-y-lg: calc_rem(9.5px - $btn-border-width);
$btn-padding-x-lg: calc_rem(24px - $btn-border-width);
$btn-font-size-lg: $font-size-lg;
$btn-line-height-lg: $btn-line-height;
$btn-border-radius-sm: $border-radius;

$btn-disabled-opacity: 0.16;

// Forms
$label-margin-bottom: calc_rem(4px);

$input-border-width: 2px;
$input-padding-y: calc_rem(12px - $input-border-width);
$input-padding-x: calc_rem(16px - $input-border-width);
$input-font-size: $font-size-base;
$input-line-height: $line-height-base;

$input-padding-y-sm: calc_rem(6.5px - $input-border-width);
$input-padding-x-sm: calc_rem(12px - $input-border-width);
$input-font-size-sm: $font-size-sm;
$input-line-height-sm: $line-height-sm;

$input-padding-y-lg: calc_rem(9.5px - $input-border-width);
$input-padding-x-lg: calc_rem(20px - $input-border-width);
$input-font-size-lg: $font-size-lg;
$input-line-height-lg: $line-height-lg;

$input-disabled-bg: $gray-100;

$input-color: $body-color;
$input-border-color: $gray-400;
$input-box-shadow: none;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius;
$input-border-radius-sm: $border-radius;

$input-focus-bg: $input-bg;
$input-focus-border-color: $primary-400;
$input-focus-color: $input-color;
$input-focus-width: 0;
$input-focus-box-shadow: none;

$input-placeholder-color: $gray-500;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: calc_rem(44px);
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y);
$input-height-inner-quarter: add(
  $input-line-height * 0.25em,
  $input-padding-y / 2
);

$input-height: calc_rem(48px);
$input-height-sm: calc_rem(32px);
$input-height-lg: calc_rem(48px);

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-text-margin-top: 0;

$form-grid-gutter-width: 8px;
$form-group-margin-bottom: calc_rem(24px);

$input-group-addon-color: $input-color;
$input-group-addon-bg: $white;
$input-group-addon-border-color: $input-border-color;

$custom-forms-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$custom-control-gutter: calc_rem(12px);
$custom-control-spacer-x: calc_rem(24px);
$custom-control-cursor: null;

$custom-control-indicator-size: calc_rem(24px);
$custom-control-indicator-bg: $input-bg;

$custom-control-indicator-bg-size: 100% 100%;
$custom-control-indicator-box-shadow: $input-box-shadow;
$custom-control-indicator-border-color: $input-border-color;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-label-color: null;

$custom-control-indicator-disabled-bg: $white;
$custom-control-label-disabled-color: $body-color;

$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-disabled-bg: $custom-control-indicator-checked-bg;
$custom-control-indicator-checked-box-shadow: null;
$custom-control-indicator-checked-border-color: $primary;

$custom-control-indicator-focus-box-shadow: none;
$custom-control-indicator-focus-border-color: $input-focus-border-color;

$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: $primary-400;
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-border-color: $primary-400;

$custom-checkbox-indicator-border-radius: $border-radius-sm;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg width='24' height='24' fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='M4.5 13L9 17.5L20 6.5' stroke='#{$custom-control-indicator-checked-color}' stroke-width='1.5' stroke-linecap='round'/></svg>");

$custom-checkbox-indicator-indeterminate-bg: $primary;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5 12H19' stroke='#{$custom-checkbox-indicator-indeterminate-color}' stroke-width='1.5' stroke-linecap='round'/></svg>");
$custom-checkbox-indicator-indeterminate-box-shadow: null;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-icon-checked: none;

$custom-switch-width: calc_rem(48px);
$custom-switch-indicator-border-radius: calc_rem(14px);
$custom-switch-indicator-size: calc_rem(20px);

$custom-select-padding-y: $input-padding-y;
$custom-select-padding-x: $input-padding-x;
$custom-select-font-family: $input-font-family;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight;
$custom-select-line-height: $input-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $input-color;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $input-disabled-bg;
$custom-select-bg-size: 10px 5px; // In pixels because image dimensions
$custom-select-indicator-color: $body-color;
$custom-select-indicator: url("data:image/svg+xml,<svg width='10' height='5' viewBox='0 0 10 5' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0 0L5 5L10 0H0Z' fill='#{$custom-select-indicator-color}'/></svg>");
$custom-select-background: escape-svg($custom-select-indicator) no-repeat right
  14px center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(
  1em * 0.75,
  (2 * $custom-select-padding-y) + $custom-select-padding-x +
    $custom-select-indicator-padding
);
$custom-select-feedback-icon-position: center right
  ($custom-select-padding-x + $custom-select-indicator-padding * 1.4);
$custom-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half;

$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $input-border-radius;
$custom-select-box-shadow: $input-box-shadow;

$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: $input-focus-box-shadow;

$custom-select-padding-y-sm: $input-padding-y-sm;
$custom-select-padding-x-sm: $input-padding-x-sm;
$custom-select-font-size-sm: $input-font-size-sm;
$custom-select-height-sm: $input-height-sm;

$custom-select-padding-y-lg: $input-padding-y-lg;
$custom-select-padding-x-lg: $input-padding-x-lg;
$custom-select-font-size-lg: $input-font-size-lg;
$custom-select-height-lg: $input-height-lg;

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: $input-focus-box-shadow;
$custom-file-disabled-bg: $input-disabled-bg;

$custom-file-padding-y: $input-padding-y;
$custom-file-padding-x: $input-padding-x;
$custom-file-line-height: $input-line-height;
$custom-file-font-family: $input-font-family;
$custom-file-font-weight: $input-font-weight;
$custom-file-color: $input-placeholder-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: $input-box-shadow;
$custom-file-button-color: $custom-control-indicator-checked-color;
$custom-file-button-bg: $custom-control-indicator-checked-bg;

// Form validation
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: theme-color('success');
$form-feedback-invalid-color: theme-color('danger');

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$form-feedback-icon-valid-color}' viewBox='0 0 16 16'><path d='M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z'/><path d='M10.97 4.97a.235.235 0 00-.02.022L7.477 9.417 5.384 7.323a.75.75 0 00-1.06 1.06L6.97 11.03a.75.75 0 001.079-.02l3.992-4.99a.75.75 0 00-1.071-1.05z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg viewBox='0 0 16 16' width='16' height='16' xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}'><g><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'></path><path d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z'></path></g></svg>");

$form-validation-states: (
  'valid': (
    'color': $form-feedback-valid-color,
    'icon': $form-feedback-icon-valid,
  ),
  'invalid': (
    'color': $form-feedback-invalid-color,
    'icon': $form-feedback-icon-invalid,
  ),
);

// Z-index master list
$zindex-dropdown: 1001;

// Navs
$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: 4px;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: $primary;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $primary;

$nav-pills-border-radius: $border-radius-sm;
$nav-pills-link-active-bg: $primary;

// Navbar
$navbar-padding-y: calc_rem(16px);
$navbar-padding-x: 0;

$navbar-brand-padding-y: 0;

$navbar-light-color: $body-color;
$navbar-light-hover-color: $navbar-light-color;
$navbar-light-active-color: $navbar-light-color;
$navbar-light-disabled-color: rgba($navbar-light-color, 0.3);
$navbar-light-toggler-border-color: rgba($navbar-light-color, 0.1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;

// Dropdowns
$dropdown-min-width: 10rem;
$dropdown-spacer: 0;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-border-color: $border-color;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 2px;
$dropdown-inner-border-radius: subtract(
  $dropdown-border-radius,
  $dropdown-border-width
);
$dropdown-divider-bg: $border-color;
$dropdown-divider-margin-y: 0;
$dropdown-box-shadow: $box-shadow-xl;

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary-400;

$dropdown-link-active-color: $white;
$dropdown-link-active-bg: $primary-800;

$dropdown-link-disabled-color: $text-muted;

$dropdown-item-padding-y: calc_rem(8px);
$dropdown-item-padding-x: calc_rem(16px);

$dropdown-header-color: $text-muted;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;

// Pagination
$pagination-padding-y: calc_rem(5px);
$pagination-padding-x: calc_rem(5px);
$pagination-line-height: $line-height-base;

$pagination-color: $body-color;
$pagination-bg: transparent;
$pagination-border-width: 3px;
$pagination-border-color: transparent;

$pagination-focus-box-shadow: none;

$pagination-hover-color: $body-color;
$pagination-hover-bg: transparent;
$pagination-hover-border-color: $primary;

$pagination-active-color: $white;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;

$pagination-disabled-color: $primary;
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: transparent;

// Cards
$card-spacer-x: calc_rem(40px);
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-inner-border-radius: $card-border-radius;

// Tooltips
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 240px;
$tooltip-color: $body-color;
$tooltip-bg: $white;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;
$tooltip-padding-y: calc_rem(10px);
$tooltip-padding-x: calc_rem(14px);
$tooltip-margin: calc_rem(6px);

$tooltip-arrow-width: calc_rem(16px);
$tooltip-arrow-height: calc_rem(8px);
$tooltip-arrow-color: $primary-400;

// Popovers
$popover-font-size: $font-size-sm;
$popover-border-width: 1px;
$popover-border-color: $border-color;
$popover-border-radius: $border-radius;
$popover-inner-border-radius: subtract(
  $popover-border-radius,
  $popover-border-width
);
$popover-box-shadow: $box-shadow;

$popover-header-bg: $gray-100;
$popover-header-color: $body-color;

$popover-body-color: $body-color;

$popover-arrow-color: $popover-bg;
$popover-arrow-outer-color: $popover-border-color;

// Toasts
$toast-padding-x: calc_rem(24px);
$toast-padding-y: calc_rem(12px);
$toast-font-size: $font-size-sm;
$toast-color: $white;
$toast-background-color: $black;
$toast-border-width: 0;
$toast-border-radius: $border-radius;
$toast-box-shadow: $box-shadow;

// Bootstrap Vue toast bottom offset
$b-toaster-offset-left: calc_rem(16px);
$b-toaster-offset-right: calc_rem(16px);
$b-toaster-offset-bottom: calc_rem(16px);

$toast-header-color: $white;
$toast-header-background-color: $black;
$toast-header-border-color: transparent;

// Badges
$badge-font-size: $small-font-size;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: 0.266667em;
$badge-padding-x: 0.533334em;
$badge-border-radius: $border-radius-sm;

$badge-focus-width: 0;

// Modals
$modal-inner-padding: calc_rem(48px);

$modal-dialog-margin: calc_rem(10px);

$modal-footer-margin-between: calc_rem(8px);

$modal-title-line-height: $headings-line-height;

$modal-content-border-color: $border-color;
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius;
$modal-content-inner-border-radius: subtract(
  $modal-content-border-radius,
  $modal-content-border-width
);
$modal-content-box-shadow-xs: $box-shadow-lg;
$modal-content-box-shadow-sm-up: $box-shadow-lg;

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.9;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: calc_rem(24px);
$modal-header-padding-x: calc_rem(64px);
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x;

$modal-xl: 1152px;
$modal-lg: 800px;
$modal-md: 700px;
$modal-sm: 500px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1.02);

// Alerts
$alert-padding-y: calc_rem(8px);
$alert-padding-x: calc_rem(32px);
$alert-margin-bottom: 0;
$alert-border-radius: 0;
$alert-link-font-weight: $font-weight-normal;
$alert-border-width: 0;

$alert-bg-level: -10;
$alert-border-level: 3.8;
$alert-color-level: 7.5;

// Different color levels for alert-warning
$alert-warning-bg-level: -8.72;
$alert-warning-border-level: 4.36;
$alert-warning-color-level: 8.75;

// List group
$list-group-item-padding-y: calc_rem(12px);
$list-group-item-padding-x: calc_rem(8px);

$list-group-hover-bg: $gray-100;
$list-group-active-color: $body-color;
$list-group-active-bg: $primary-100;
$list-group-active-border-color: $border-color;

$list-group-action-color: $body-color;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $primary-100;

// Figures
$figure-caption-font-size: $small-font-size;
$figure-caption-color: $text-muted;

// Breadcrumbs
$breadcrumb-font-size: $font-size-sm;

$breadcrumb-padding-y: calc_rem(8px);
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: calc_rem(4px);

$breadcrumb-margin-bottom: 0;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-400;
$breadcrumb-active-color: $body-color;

$breadcrumb-border-radius: 0;

// Close
$close-font-weight: $font-weight-normal;
$close-color: $body-color;
$close-text-shadow: none;
