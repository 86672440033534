.nowrap {
  white-space: nowrap;
}

html,
body {
  min-width: 320px;
  min-height: 100%;
}

#app {
  min-height: 100%;
  z-index: -1;
  background-color: $gray-100;
}

.error-section {
  min-height: 55vh;
}

.flash-msg {
  z-index: 24;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.flash-msg-offset-compensator {
  position: relative;
}

.fond-logo {
  border: $border-width solid $border-color;
  border-right: none;
  border-radius: $border-radius-sm 0 0 $border-radius-sm;
  box-shadow: $box-shadow;
  position: fixed;
  right: 0;
  bottom: calc_rem(16px);
  width: 88px;
  z-index: 3;
  overflow: hidden;
  background-color: $white;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s, visibility 0s 0s;

  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0s 0.2s;
  }
}

.text-countdown {
  font-size: $font-size-xs;
}

.grecaptcha-badge {
  visibility: hidden;
}

.ellipsis-string-container {
  position: relative !important;
}

.ellipsis-string-container:before {
  content: '&nbsp;' !important;
  visibility: hidden !important;
}

.ellipsis-string-container {
  a,
  span {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

.demand-find-map-panel,
.service-find-map-panel {
  background-color: $gray-100;

  @include media-breakpoint-up(lg) {
    height: 700px;
  }
}

.adm-logo {
  opacity: 0.5;
  animation: rotating-static 40s ease-in-out infinite;
}

.rotating {
  height: 32px;
  width: 32px;
  padding: calc_rem(4px);
  animation: rotating 3s ease-in-out infinite;
}

.loading-indicator {
  animation: invoke 1s;
  transition: all 1s;
}

img {
  max-width: 100%;
  height: auto;
}

@include media-breakpoint-up(lg) {
  .feature {
    min-height: 500px;
  }
}

.count-down-popup {
  z-index: 1050;
}

.count-down-popup-fixed {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
}

.render-default-replacer {
  position: relative;
  height: 40px;
}

.render-extra-offset {
  position: relative;
  height: 40px;
}

.count-down-popup-fixed-with-offset {
  top: 40px;
}

.subcategory-slot:hover {
  background-color: $white;
}

.user-road-map {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  .deal-step {
    margin: auto;
    transition: all 0.2s;
    color: #dcdcdc;
    text-align: center;
  }

  .deal-step:hover {
    cursor: pointer;
    transition: all 0.2s;
  }

  .current-step {
    color: $primary;
    border-color: $primary;
  }

  .step-ahead {
    color: grey;
  }
  .step-behind {
    color: $success;
  }
}

.not-collapsed {
  .bi-chevron-down {
    transition: all 0.2s;
  }
}

.collapsed {
  .bi-chevron-down {
    transition: all 0.2s;
    transform: rotate(90deg);
  }
}

.cards-with-pagination {
  background-color: $gray-100;
  transition: $transition-base;

  @include media-breakpoint-up(lg) {
    height: 100%;
    overflow-y: auto;
  }
}

.no-results-found {
  width: 300px;
  height: 300px;
  flex: 0 0 300px;
  border-radius: 50rem;
  background-color: $white;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.scroll-to-top {
  position: fixed;
  bottom: calc_rem(20px);
  right: calc_rem(12px);
  z-index: 2;

  @include media-breakpoint-up(md) {
    bottom: calc_rem(12px);
  }
}
