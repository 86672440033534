.text-md {
  font-size: $font-size-base;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

h1,
.h1,
h2,
.h2 {
  &:not(:first-child) {
    margin-top: calc_rem(40px);
  }
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  &:not(:first-child) {
    margin-top: calc_rem(32px);
  }
}

h4,
.h4 {
  letter-spacing: 0.01em;
}

h5,
.h5 {
  letter-spacing: 0.02em;
}

h6,
.h6 {
  letter-spacing: 0.1em;
  margin-bottom: calc_rem(16px);
  text-transform: uppercase;
}

a {
  word-break: break-word;
}

.display-1,
.display-2 {
  word-break: break-word;
}

@include media-breakpoint-up(md) {
  h1,
  .h1 {
    font-size: calc_rem(40px);
    letter-spacing: -0.02em;
  }

  h2,
  .h2 {
    font-size: calc_rem(30px);
  }

  h1,
  .h1,
  h2,
  .h2 {
    &:not(:first-child) {
      margin-top: calc_rem(48px);
    }
  }

  h3,
  .h3 {
    letter-spacing: 0.01em;
  }

  .display-1 {
    font-size: calc_rem(48px);
    letter-spacing: -0.02em;
  }

  .display-2 {
    font-size: calc_rem(40px);
    letter-spacing: -0.02em;
  }

  .lead {
    font-size: $font-size-lg;
  }
}

pre {
  white-space: pre-wrap;
  font-family: $font-family-sans-serif;
  font-size: inherit;
}

// Content list styles
ul,
ol {
  &.list-styled {
    list-style: none;
    margin: calc_rem(24px) 0;
    padding-left: calc_rem(24px);

    &:first-child {
      margin-top: 0;
    }

    li {
      position: relative;

      &:not(:last-child) {
        margin-bottom: calc_rem(8px);
      }

      &::before {
        color: $primary;
        margin-left: calc_rem(-24px);
      }

      > ol,
      > ul {
        margin: calc_rem(8px) 0 calc_rem(8px) calc_rem(16px) !important;
      }
    }
  }
}

ul {
  &.list-styled {
    li {
      &::before {
        content: '\2022';
        position: absolute;
        top: 0;
      }
    }
  }
}

ol {
  &.list-styled {
    padding-left: calc_rem(30px);
    counter-reset: alphaCounter;

    > li {
      counter-increment: alphaCounter;

      &::before {
        content: counter(alphaCounter) '.';
        font-weight: bold;
        text-align: left;
        display: inline-block;
        vertical-align: baseline;
        margin-right: 0.55rem;
      }
    }

    ol {
      counter-reset: betaCounter;

      > li {
        counter-increment: betaCounter;
      }

      li {
        &::before {
          content: counter(betaCounter) '.';
        }
      }
    }

    // Generate numbers for OL elements with start attribute
    @for $i from 1 through 50 {
      &[start='#{$i}'] {
        counter-reset: alphaCounter $i - 1;
      }
    }
  }
}
