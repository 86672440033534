.opacity-1 {
  opacity: 1 !important;
}

.object-cover {
  object-fit: cover;
}

.placeholder-dark {
  &::placeholder {
    opacity: 1;
    color: $body-color;
  }
}

@include media-breakpoint-up(sm) {
  .row-sm-narrow {
    margin-left: -$grid-gutter-width / 4;
    margin-right: -$grid-gutter-width / 4;

    > .col,
    > [class*='col-'] {
      padding-left: $grid-gutter-width / 4;
      padding-right: $grid-gutter-width / 4;
    }
  }
}
