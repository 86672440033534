.cta {
  position: relative;
  z-index: 0;
  background-color: $gray-100;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    min-height: 500px;
  }
}

.cta-background {
  @include media-breakpoint-down(lg) {
    img {
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    bottom: 0;
    float: right;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        $gray-100 0%,
        rgba($gray-100, 0.85) 27.08%,
        rgba($white, 0) 100%
      );
    }

    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }
}
