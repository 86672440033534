.wizard {
  margin: 0 calc_rem(-16px);

  @include media-breakpoint-up(md) {
    margin: 0 calc_rem(-28px);
  }

  .step {
    position: relative;
    z-index: 1;
    width: calc_rem(40px);
    padding: 0 calc_rem(16px);
    box-sizing: content-box;

    @include media-breakpoint-up(md) {
      padding: 0 calc_rem(28px);

      + .step {
        .wizard-icon {
          &::before {
            content: '';
            position: absolute;
            width: calc_rem(40px);
            bottom: calc_rem(20px);
            transform: translate(-50%, 50%);
            left: 0;
            background: $border-color;
            height: 2px;
            border-radius: 1px;
            z-index: -1;
          }
        }
      }
    }

    .step-title {
      margin: 0 calc_rem(-32px) calc_rem(8px);
      font-size: $font-size-xs;

      @include media-breakpoint-up(md) {
        font-size: $font-size-sm;
      }
    }

    .wizard-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50rem;
      border: 2px solid $gray-400;
      height: calc_rem(40px);
      width: calc_rem(40px);
      margin: 0 auto;
      font-weight: $font-weight-bold;
    }

    &.active {
      .step-title {
        font-weight: $font-weight-bold;
      }

      .wizard-icon {
        border-color: $success;
        background-color: $success;
        color: $white;
      }
    }

    &.completed {
      .wizard-icon {
        border-color: $success;
        background-color: $success-light;

        .b-icon {
          color: $success-dark;
        }
      }
    }
  }
}
