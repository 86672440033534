.online-indicator-dot {
  display: inline-block;
  vertical-align: text-top;
  margin-top: 0.14em;
  margin-left: calc_rem(4px);
  border-radius: 50rem;
  width: calc_rem(8px);
  height: calc_rem(8px);
  animation: online-indicator 2s infinite;
  position: relative;
  z-index: 1;
}

.deal-status-indicator {
  display: inline-block;
  position: relative;
  top: -0.15em;
  margin-top: 0.25em;
  margin-left: calc_rem(4px);
  border-radius: 50rem;
  width: calc_rem(8px);
  height: calc_rem(8px);
  flex: 0 0 calc_rem(8px);
}

.btn-anim-pulse-green {
  animation: pulse-green 2s infinite;
}

.btn-anim-pulse-blue {
  animation: pulse-blue 2s infinite;
}

.anim-icon-blue {
  animation: icon-pulse-blue 2s infinite;
}

.anim-icon-red {
  animation: icon-pulse-opacity 2s infinite;
}

.btn-anim-pulse-notifications {
  transition: all 1.5s;
  animation: pulse-notification 3.7s infinite;
  height: calc_rem(32px);
  min-width: calc_rem(32px);
  max-width: calc_rem(32px);
  border: 2px solid $white;

  .b-avatar-text {
    font-size: $font-size-xs;
    line-height: $line-height-base;
    letter-spacing: initial;

    span {
      color: $white;
    }
  }
}
