.messenger {
  border: 2px solid $border-color;
  border-radius: $border-radius;
  background-color: $white;

  .btn-message-send {
    min-width: max-content;

    @include media-breakpoint-down(sm) {
      flex: 1 1 auto;
    }
  }

  .spinner-border {
    position: absolute;
    z-index: 5;
    top: 6px;
    right: 100px;
  }

  .custom-file {
    display: flex;

    + .file-uploading {
      color: $primary;
      padding-left: calc_rem(8px);
      padding-right: calc_rem(24px);
    }
  }

  .custom-file-input {
    width: 0;
    max-width: 0;

    &:focus {
      ~ .custom-file-label {
        box-shadow: inset 0 0 0 3px $primary-800;
        background-color: $gray-100;

        &::before {
          background-image: escape-svg(
            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$primary-800}' viewBox='0 0 16 16'><path d='M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z'/><path d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z'/></svg>")
          );
        }

        &::after {
          color: $primary-800;
          background-color: transparent;
        }
      }

      &::after {
        background-color: transparent;
      }
    }

    &[disabled],
    &:disabled {
      ~ .custom-file-label {
        pointer-events: none;
        opacity: $btn-disabled-opacity;
      }
    }

    @include media-breakpoint-down(xs) {
      ~ .custom-file-label {
        &::after {
          content: '';
          padding-left: 0;
        }
      }
    }
  }

  .custom-file-label {
    border: none;
    cursor: pointer;
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    background-color: $white;
    box-shadow: inset 0 0 0 2px $primary;

    &:hover {
      box-shadow: inset 0 0 0 3px $primary;
      background-color: $gray-100;

      &::after {
        background-color: transparent;
      }
    }

    .form-file-text {
      display: none;
    }

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      margin-top: calc_rem(-2px);
      margin-left: calc_rem(24px);
      background: escape-svg(
        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$custom-control-indicator-checked-bg}' viewBox='0 0 16 16'><path d='M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z'/><path d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z'/></svg>")
      );
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &::after {
      position: relative;
      padding-left: calc_rem(8px);
      padding-right: calc_rem(24px);
      background-color: transparent;
      color: $primary;
    }
  }

  .message-files {
    border-top: 2px solid $border-color;
  }

  .messages-layout {
    height: 380px;
    overflow-y: auto;

    .message-date {
      font-size: $font-size-xs;
      color: $text-muted;
    }

    .messages {
      width: fit-content;
      padding: calc_rem(6px) calc_rem(16px) calc_rem(8px);
      border-radius: $border-radius-lg;
      word-wrap: break-word;

      p {
        word-break: break-word;
        margin-bottom: 0;
      }

      .link-style {
        font-size: $font-size-sm;
        text-decoration: underline;
      }
    }

    .primary-msg {
      background-color: $primary-400;
      margin-left: auto;

      p,
      .link-style {
        color: $white;
      }
    }

    .secondary-msg {
      background-color: $gray-200;
      margin-right: auto;

      p,
      .link-style {
        color: $body-color;
      }
    }
  }
}
