.payment-methods {
  @include media-breakpoint-up(md) {
    max-width: 560px;
  }

  @include media-breakpoint-down(sm) {
    .payment-method-logo {
      max-width: 125px;
    }
  }

  .payment-method-links {
    margin: 0 calc_rem(-6px) calc_rem(-4px);
  }

  .payment-method-link {
    margin: calc_rem(4px) calc_rem(6px);
  }
}
