.select-service-form {
  @include media-breakpoint-down(xs) {
    background-color: transparent;
    box-shadow: none;

    .card-body {
      padding: 0;
    }

    .btn-outline-primary {
      background-color: $white;
    }
  }

  @include media-breakpoint-up(sm) {
    box-shadow: $box-shadow-sm;
  }

  .find-service-form {
    .custom-control-single-select {
      .multiselect__placeholder {
        color: $body-color;
        margin-bottom: 0;
      }

      &.multiselect--active {
        .multiselect__placeholder {
          display: inline-block;
        }
      }

      .multiselect__single {
        height: 100%;
        span {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}
