.sticky-nav {
  transition: transform 0.2s;

  &.scroll-down {
    transform: translateY(-100%);
  }

  .has-count-down & {
    top: 40px;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0s 0.2s;
  background-color: $white;
  z-index: $zindex-fixed;

  .has-count-down & {
    top: 40px;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s, visibility 0s 0s;
  }
}

.mobile-menu-header {
  flex: 0 0 55px;
  max-height: 55px;
  padding-top: calc_rem(4px);
  padding-bottom: calc_rem(3px);
  z-index: 1;
}

.mobile-menu-content {
  background-color: $gray-100;
  overflow: auto;
  flex: 1 1 auto;

  li {
    + li {
      margin-top: calc_rem(12px);
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.nav-overview {
  .nav-link {
    padding: calc_rem(18px) calc_rem(16px);

    &.active {
      background-color: transparent;
    }

    .badge {
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
      vertical-align: text-top;
      padding: calc_rem(2px) calc_rem(4px);
      margin-left: 2px;
      margin-top: -2px;
    }
  }
}
