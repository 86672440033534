a.badge {
  text-decoration: none;
}

.badge-white {
  @include badge-variant($white);
}

.badge-pill {
  min-width: calc_rem(16px);
}
