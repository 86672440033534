.b-rating {
  @include media-breakpoint-up(lg) {
    height: calc_rem(64px);
  }

  &:not(.disabled):not(.readonly) {
    .b-rating-star {
      &:hover {
        .b-rating-icon {
          background-color: $warning;
          transform: scale(1.15);
        }
      }

      &.b-rating-star-full {
        &:hover {
          .b-rating-icon {
            background-color: $success-light;
            color: $success-text;
          }
        }
      }
    }
  }

  .b-rating-star {
    padding: 0;
    margin-right: calc_rem(8px);

    &:last-child {
      margin-right: 0;
    }

    &.b-rating-star-full {
      .b-rating-icon {
        background-color: $success;
        color: $white;
      }
    }

    .b-rating-icon {
      padding: calc_rem(8px);
      background-color: $warning-light;
      font-size: calc_rem(24px);
      color: $warning-text;
      border-radius: 50rem;
    }

    @include media-breakpoint-up(lg) {
      margin-right: calc_rem(16px);

      .b-rating-icon {
        padding: calc_rem(16px);
      }
    }
  }
}
