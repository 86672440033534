.input-group {
  .form-control {
    + .input-group-append {
      > * {
        transition: all 0.15s ease-in-out;
      }
    }

    &:focus {
      + .input-group-append {
        > * {
          border-color: $input-focus-border-color;
        }
      }
    }

    &.is-valid {
      + .input-group-append {
        > * {
          border-color: $success;
        }
      }
    }

    &.is-invalid {
      + .input-group-append {
        > * {
          border-color: $danger;
        }
      }
    }
  }
}

.input-group-text {
  height: $input-height;
}
