.breadcrumb {
  padding-top: $breadcrumb-padding-y * 2;

  .b-icon {
    fill: $primary;
    font-size: calc_rem(16px);

    &:hover {
      fill: currentColor;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $primary-800;
    }
  }
}
