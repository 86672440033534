.card {
  box-shadow: $box-shadow-xs;

  @include media-breakpoint-up(md) {
    border-radius: $border-radius-lg;
  }
}

@include media-breakpoint-up(md) {
  .card-header {
    &:first-child {
      @include border-radius($border-radius-lg $border-radius-lg 0 0);
    }
  }
}

.card-body {
  padding: calc_rem(24px) calc_rem(24px) calc_rem(40px);

  @include media-breakpoint-up(md) {
    padding: calc_rem(24px) $card-spacer-x calc_rem(48px);
  }
}

.accordion {
  [aria-expanded] {
    text-decoration: none;
    color: $body-color;

    .b-icon {
      transition: $transition-base;
    }

    &:hover {
      text-decoration: underline;
    }

    &.not-collapsed {
      .bi-chevron-right {
        transform: rotate(90deg);
      }

      .bi-chevron-left {
        transform: rotate(-90deg);
      }
    }
  }
}
