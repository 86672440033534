.login {
  .auth-method-logo {
    width: 100px;
  }

  .callback-code {
    background-color: theme-color-level('warning', $alert-warning-bg-level);
    border: 2px solid theme-color-level('warning', $alert-warning-border-level);
    border-radius: $border-radius;
    text-align: center;
  }
}
