.active-chat {
  .card {
    box-shadow: $box-shadow-sm;
    transition: $transition-base;

    &:hover,
    &:focus {
      box-shadow: $box-shadow-lg;

      .username {
        text-decoration: underline;
      }
    }
  }

  .deal-single-chat {
    border-left: none;
    border-right: none;

    &:hover,
    &:focus,
    &.active {
      .deal-single-chat-title {
        text-decoration: underline;
      }
    }

    .badge-event-label {
      left: 0;
    }
  }

  .chat-item {
    .badge-amount {
      font-size: $font-size-xs;
    }
  }

  .b-sidebar {
    padding: 0 calc_rem(8px);

    &.b-sidebar-right {
      > .b-sidebar-header {
        position: absolute;
        z-index: 1;
        top: 0;
        right: calc_rem(16px);

        .close {
          margin-right: 0;
          margin-left: auto;
          display: inline-flex;
          background: $white;
          border-radius: 50rem;
          padding: calc_rem(8px);
          box-shadow: $box-shadow-sm;
          opacity: 1;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .b-sidebar {
      background-color: $white !important;
      justify-content: flex-end;
    }

    .b-sidebar-body {
      flex-grow: 0;
      height: auto;
    }

    .messages-layout {
      height: auto;
      overflow: visible;
    }
    
    .messenger {
      padding-top: calc_rem(64px);
      border: none;
      overflow: hidden;
    }
  }

  @include media-breakpoint-up(md) {
    .b-sidebar-outer {
      position: relative;
      z-index: 1;
      height: auto;
    }

    .b-sidebar-header {
      display: none;
    }

    .b-sidebar {
      padding: 0;
      position: relative;
      height: auto;
      transform: none;
      display: flex;
      transition: none;
    }
  }
}
