.nav-tabs {
  .nav-link {
    border-width: 0 0 $nav-tabs-border-width;
    font-weight: $font-weight-bold;
    padding: calc_rem(10px) calc_rem(16px);

    &:hover {
      background-color: $primary-100;
      color: $nav-tabs-link-active-color;
    }

    &.active,
    .nav-item.show & {
      background-color: $nav-tabs-link-active-bg;
    }
  }

  .multi-tab-textarea & {
    margin-left: calc_rem(16px);
    border: none;
    border-bottom: 2px solid $primary;

    .nav-item {
      margin-bottom: 0;

      &:first-child {
        .nav-link {
          border-top-left-radius: $border-radius;
        }
      }

      &:last-child {
        .nav-link {
          border-top-right-radius: $border-radius;
        }
      }

      + .nav-item {
        margin-left: -1px;
      }

      .b-icon {
        font-size: 100%;
      }
    }

    .nav-link {
      box-shadow: inset -1px 0 0 0 $gray-200, inset 1px 1px 0 0 $gray-200;
      border-bottom-width: 0;
      border-radius: 0;
      font-weight: normal;
      padding: calc_rem(4px) calc_rem(8px) calc_rem(4px);
      position: relative;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(sm) {
        padding-left: calc_rem(16px);
        padding-right: calc_rem(16px);
      }

      &:hover {
        background-color: transparent;
        box-shadow: inset -1px 1px 0 1px $input-border-color,
          inset 1px 1px 0 1px $input-border-color;
        z-index: 1;
        text-decoration: underline;
      }

      &.active {
        color: $white;
        background-color: $nav-tabs-link-active-border-color;
        box-shadow: inset 0 0 0 2px $nav-tabs-link-active-border-color;
        z-index: 2;

        .b-icon {
          color: $white !important;
        }
      }
    }
  }

  .multi-tab-textarea.readonly & {
    margin-left: 0;
  }

  .login & {
    background-color: $gray-100;
    border-bottom: 2px solid $gray-400;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;

    .nav-item {
      margin-bottom: -2px;

      .nav-link {
        margin-bottom: 0;
      }

      &:first-child {
        .nav-link {
          border-top-left-radius: $border-radius-lg;
        }
      }

      &:last-child {
        .nav-link {
          border-top-right-radius: $border-radius-lg;
        }
      }
    }

    .nav-link {
      border-bottom-width: 2px;
      min-height: calc_rem(64px);

      @include media-breakpoint-up(md) {
        min-height: calc_rem(80px);
      }

      &:hover {
        background-color: $white;
        box-shadow: inset 0 -2px 0 0 $primary;
      }
    }
  }
}

// Tabbable tabs
.multi-tab-textarea {
  p {
    white-space: pre-wrap;
  }

  &.readonly {
    .tab-content {
      padding-top: calc_rem(24px);
    }
  }
}
