.international-phone-number {
  .country-code {
    // flex: 0 1 128px;
    min-width: 128px;
  }

  .country-code-option {
    display: flex;
    align-items: center;
  }

  .country-flag {
    display: inline-flex;
    position: relative;
    // Image size in px
    flex: 0 0 27px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0px 0px 1px 0px rgba($black, 0.35);
    }

    img {
      // Image size in px
      width: 27px;
    }
  }

  .form-group {
    margin-bottom: 0;
    flex: 1 1 auto;
  }

  .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.is-invalid {
    .form-control {
      border-color: $danger;
      padding-right: $input-height-inner;
      background-image: escape-svg($form-feedback-icon-invalid);
      background-position: right $input-height-inner-quarter center;
      background-repeat: no-repeat;
      background-size: $input-height-inner-half $input-height-inner-half;
    }
  }
}
