.navbar {
  padding-bottom: calc_rem(15px);

  &.navbar-light {
    .nav-item {
      margin-right: calc_rem(32px);
    }

    .nav-link {
      font-weight: $font-weight-bold;
      // Use px units for link shadow
      transition: $transition-base;
      padding: 0;

      &.hover-shadow {
        padding-bottom: 3px;

        &:hover {
          box-shadow: inset 0 -2px 0 0 $primary;
        }

        &.router-link-active,
        &:active,
        &:focus {
          box-shadow: inset 0 -2px 0 0 $primary-800;
        }
      }
    }

    .nav-item-message {
      .nav-link {
        .app-icon-lg,
        .b-icon,
        .chat-events-counter {
          transition: $transition-base;
        }

        &:hover,
        &.router-link-active,
        &:active,
        &:focus {
          .app-icon-lg,
          .b-icon {
            transform-origin: bottom left;
            transform: scale(1.166667);
          }

          .chat-events-counter {
            margin: calc_rem(-2px) calc_rem(-2px) calc_rem(2px) calc_rem(2px);
          }

          .b-icon {
            color: $primary-400 !important;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-brand {
    max-width: 112px;
    font-size: 0;
  }
}
