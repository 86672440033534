.dropdown-with-checkbox-options {
  .dropdown-menu {
    overflow: auto;
    max-height: 95%;
    height: 360px;
    max-width: calc(100vw - #{calc_rem(20px)} - #{$grid-gutter-width});
  }

  .dropdown-toggle {
    overflow: hidden;
    border-radius: $custom-select-border-radius;
    transition: $input-transition;
    padding: $custom-select-padding-y $custom-select-padding-x;
    height: $custom-select-height;
    line-height: $custom-select-line-height;
    font-weight: $input-font-weight;

    &,
    .show &,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:hover {
      border: $custom-select-border-width solid $custom-select-border-color;
      background: $custom-select-bg $custom-select-background;
      color: $custom-select-color;
    }

    &::after {
      display: none;
    }
  }

  .is-invalid {
    .dropdown-toggle {
      border-color: $danger !important;
    }
  }
}
