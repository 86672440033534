.modal-header {
  padding: calc_rem(16px) calc_rem(16px);

  @include media-breakpoint-up(md) {
    padding: $modal-header-padding;
  }

  .close {
    padding: 0;
    margin: calc_rem(4px) 0 0 calc_rem(16px);

    @include media-breakpoint-up(md) {
      margin-top: calc_rem(6px);
      margin-right: calc_rem(-40px);
    }

    svg {
      display: block;
    }
  }
}

@include media-breakpoint-down(xs) {
  .modal-dialog {
    margin: 0;
  }

  .modal-dialog-centered {
    min-height: 100%;
  }

  .modal-dialog-scrollable {
    max-height: 100%;
  }

  .modal-header,
  .modal-content,
  .modal-footer {
    border-radius: 0;
  }
}

.has-count-down {
  .modal-dialog {
    padding-top: 40px;
  }
}

.modal-body {
  padding: calc_rem(24px) calc_rem(16px);

  @include media-breakpoint-up(md) {
    padding: calc_rem(48px) calc_rem(64px);
  }
}

.modal-footer {
  $modal-footer-spacing: $modal-footer-margin-between / 2;
  padding: calc_rem(16px) - $modal-footer-spacing;

  @include media-breakpoint-down(xs) {
    > * {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    padding: calc_rem(16px) - $modal-footer-spacing calc_rem(40px) -
      $modal-footer-spacing;
  }
}

.modal-dialog-close {
  position: absolute;
  top: calc_rem(16px);
  right: calc_rem(24px);
}
