// Make icons larger in buttons, nav-links, dropdowns, and input-group-text
.btn,
.dropdown-toggle,
.dropdown-item,
.input-group-text,
.multiselect__single {
  .b-icon {
    &.bi {
      font-size: 133.4%;
      margin-bottom: calc_rem(-1px);
    }
  }
}

.nav-link {
  .b-icon {
    &.bi {
      font-size: 133.4%;
    }
  }
}

.nav-tabs {
  .login & {
    .b-icon {
      font-size: 152.4%;
      margin-bottom: -0.125em;
    }
  }
}

.badge {
  .b-icon {
    &.bi {
      font-size: 1.066667em;
      margin-bottom: -0.05em;
    }
  }
}

.alert-dismissible {
  .close {
    .b-icon {
      display: block;
      transform: scale(1.144);
    }
  }
}

.toast,
.language-dropdown {
  .b-icon {
    &.bi {
      font-size: 160.1%;
    }
  }
}

.input-group-text {
  .b-icon {
    &.bi {
      font-size: 125%;
      vertical-align: text-top !important;
    }
  }
}
