.gm-style {
  // Infowindow styles
  .gm-style-iw {
    font-size: $font-size-base;
    font-family: $font-family-base;
    font-weight: $font-weight-base;
    max-width: 320px !important;
  }

  // Infowindow styles
  .gm-style-iw-c {
    box-shadow: $box-shadow-lg;
    padding: calc_rem(24px) !important;
    border-radius: $border-radius;

    // Infowindow close button
    > button {
      top: 0 !important;
      right: 0 !important;
      outline: none;

      img {
        width: 20px !important;
        height: 20px !important;
        margin: 5px !important;
      }
    }
  }

  // Infowindow overflow
  .gm-style-iw-d {
    overflow: auto !important;
  }

  // Infowindow arrow
  .gm-style-iw-t {
    &::after {
      width: 22px;
      height: 22px;
    }
  }

  // Zoom controls
  .gm-bundled-control-on-bottom {
    .gmnoprint {
      > div {
        border-radius: $border-radius !important;
        box-shadow: $box-shadow-xs !important;
      }
    }
  }
}

// Marker cluster styles
.app-gmap-cluster {
  > div {
    display: inline-block;
    background-color: $primary;
    color: $white;
    padding: $badge-padding-y $badge-padding-x;
    text-align: center;
    font-size: $font-size-base;
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    white-space: nowrap;
    vertical-align: baseline;
    border: 6px solid $white;
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    border-radius: $badge-pill-border-radius;
    transition: $transition-base;

    &:hover {
      background-color: $primary-800;
    }
  }
}

// Map size control
.app-gmap-resize {
  position: absolute;
  z-index: 1;
  top: calc_rem(16px);
  left: calc_rem(16px);
  background-color: $white;
  color: $primary;
  border-radius: $border-radius;
  box-shadow: $box-shadow-sm;
  transition: $transition-base;

  &:hover {
    background-color: $primary-400;
    color: $white;
  }
}

.app-gmap-toggle-wrap {
  position: sticky;
  bottom: calc_rem(-24px);
  z-index: 3;
}

.app-gmap-distance-label {
  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
  line-height: $line-height-base;
  padding: calc_rem(1px) calc_rem(6px);
}

.app-gmap-custom-marker {
  font-size: $font-size-base;
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
}

.app-gmap-wrapper {
  z-index: 2;

  @include media-breakpoint-down(md) {
    .vue-map-container {
      position: absolute;
      height: 100vh !important;
      width: 100%;

      &.app-gmap-fullwidth {
        position: relative;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .vue-map-container {
    width: 100%;
    transition: width 0.2s;
  }

  .app-gmap-fullwidth {
    width: 200%;
  }
}
